<template>
    <CContainer class="d-flex align-items-center mt-2">
      <CRow class="justify-content-center col-md-12">
        <CCard
          color="white"
          text-color="dark"
          class="text-center col-md-6"
          body-wrapper>
          
          <div v-if="title != ''">
            <h5>{{ title }}</h5>
            <CAlert v-if="msgError != ''" color="danger">{{ msgError}}</CAlert>
            <CAlert v-if="msgSuccess != ''" color="success">{{ msgSuccess}}</CAlert>
          </div>
          <div v-else-if="mode == 'resetPassword'">
            <h3>{{ $t('signIn') }}</h3>
            <p class="text-left font-weight-bold text-dark">{{ $t('for') }} {{ email }}</p>
            <CForm>
              <CAlert v-if="alert != ''" color="danger" :show.sync=delayAlert closeButton>{{ alert }}</CAlert>
              <CInput
                v-model="newPassword"
                type="password"
                placeholder="New password">
                <template #prepend-content>
                  <CIcon name="cil-lock-locked" />
                </template>
              </CInput>
              <CRow>
                <CCol col="12">
                  <CButton
                    @click="confirmPasswordReset"
                    block
                    color="success"
                    class="px-4"
                    :disabled="loadingButton">
                    <CSpinner v-if='loadingButton==true' color="white" size="sm" />
                    {{ $t('save') }}
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </div>
        </CCard>
      </CRow>
    </CContainer>
</template>
  
  <script>
  import firebase from 'firebase/app'
  
  export default {
    name: 'Login',
    data() {
      return {
        email: null,
        newPassword: null,
        title: '',
        msgError: '',
        msgSuccess: '',
        alert: '',
        delayAlert: 3,
        loadingButton: false
      }
    },
    created() {
      const mode = this.$route.query.mode
      const actionCode = this.$route.query.oobCode
      const continueUrl = this.$route.query.continueUrl
      const lang = this.$route.query.lang || 'th'        
      
      switch (mode) {
        case 'resetPassword':
          this.title = this.$t('loading')
          this.verifyPasswordResetCode()
          break;
        case 'recoverEmail':
          break;
        case 'verifyEmail':
          this.title = this.$t('verifying')
          this.handleVerifyEmail()
          break;
        default:
          break;
      }
    },
    computed: {
      mode() {
        return this.$route.query.mode
      },
      oobCode() {
        return this.$route.query.oobCode
      },
    },
    methods: {
      async checkActionCode() {
        try {
          const data = await firebase.auth().checkActionCode(this.oobCode)
        } catch (error) {
          console.log(error)
        }
      },
      async confirmPasswordReset() {
        this.alert = ''
        this.delayAlert = 3
        this.loadingButton = true
        try {
          const data = await firebase.auth().confirmPasswordReset(this.oobCode, this.newPassword)
          this.title = this.$t('passwordchanged')
          this.msgSuccess = this.$t('loginNewPass')
        } catch (error) {
          this.alert = error.message
          console.log(error)
        }
        this.loadingButton = false
      },
      async verifyPasswordResetCode() {
        try {
          const data = await firebase.auth().verifyPasswordResetCode(this.oobCode)
          this.email = data
          this.title = ''
        } catch (error) {
          this.title = this.$t('tryresetpassword')
          this.msgError = error.message
        }
      },
      async handleVerifyEmail() {
        try {
          const data = await firebase.auth().applyActionCode(this.oobCode)
          this.title = this.$t('emailVeified')
          this.msgSuccess = this.$t('signinNewAcc')
        } catch (error) {
          this.title = this.$t('tryVerifiedEmail')
          this.msgError = error.message
        }
      }
    },
  }
  </script>
  